import { head } from "lodash"

const btnInitialVideo = document.querySelector('#playVideoHeels')
btnInitialVideo.addEventListener('click', ()=>{
    const container = document.querySelector('.conforto-video')
    container.classList.add("active")

    const video = document.getElementById('videoHeels')
    video.play()

    const durationVideo = video.duration
    setTimeout(()=>{
        const container = document.querySelector('.conforto-video')
        container.classList.remove("active")
    }, durationVideo * 1100)

})


/*Zoom grid*/
$(document).on('click', '.zoomHome', function() {
    let id = $(this).attr('id');
    let bannerZoomName = $(`#bannerZoom${id}`);
    bannerZoomName.addClass("active");
});
$(document).on('click', '.closeTabGrid', function() {
    let id = $(this).attr('data-id');
    let bannerZoomName = $(`#bannerZoom${id}`);
    bannerZoomName.removeClass("active");
});


// animando entrada dos itens
let tlHome = gsap.timeline();

tlHome.fromTo(".line-1",
        {
        y:-100,
        },
        {
        duration: .7,
        y:0,
        ease: "none",
        opacity: 1
        },.5)

       .fromTo(".line-2",
       {
        y:100,
       },
       {
        y:0,
        duration: .7,
        ease: "none",
        opacity: 1
       },"<")

       
